import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Note variant="warning" mdxType="Note">This library is under active pre-1.0 development. Breaking changes are likely in patch releases.</Note>
    <h2>{`Introduction`}</h2>
    <p>{`Primer ViewComponents is an implementation of the Primer Design System for Ruby on Rails, built using `}<a parentName="p" {...{
        "href": "https://github.com/github/view_component"
      }}>{`ViewComponent`}</a>{`.`}</p>
    <p>{`New to ViewComponent? Check out `}<a parentName="p" {...{
        "href": "https://viewcomponent.org/#whats-a-viewcomponent"
      }}>{`this summary`}</a>{` for an explanation of how they work.`}</p>
    <h2>{`Background`}</h2>
    <p>{`In recent years the Rails front-end landscape has become fairly complex. In the past, the mechanism for delivering JavaScript and CSS from a Rails app was the asset pipeline, powered by the sprockets and sprockets-rails gems. The asset pipeline was responsible for compiling, minifying, digesting, and serving assets, making it an all-in-one solution.`}</p>
    <p>{`In later versions of Rails, the asset pipeline was superceded by Webpack via the webpacker gem. Webpack is highly configurable and capable of handling JavaScript and CSS via plugins. Out of the box, Rails only configures Webpack to handle JavaScript, continuing to rely on the asset pipeline for CSS.`}</p>
    <p>{`In version 7, the Rails project introduced several new gems: `}<a parentName="p" {...{
        "href": "https://github.com/rails/jsbundling-rails"
      }}>{`jsbundling-rails`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/rails/cssbundling-rails"
      }}>{`cssbundling-rails`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://github.com/rails/propshaft"
      }}>{`propshaft`}</a>{`. Rather than provide the deep integration with the Rails framework their predecessors provided, these gems delegate to command-line tools written in other languages (usually JavaScript) to process JavaScript and CSS. jsbundling-rails supports bun, esbuild, rollup, and webpack as a legacy option. cssbundling-rails supports tailwind, bootstrap, bulma, postcss, and sass. Rails auto-generates entries in package.json for `}<inlineCode parentName="p">{`build`}</inlineCode>{` and `}<inlineCode parentName="p">{`build:css`}</inlineCode>{` scripts which compile JavaScript and CSS respectively.`}</p>
    <p>{`Propshaft is a replacement for the asset pipeline (Sprockets) that offers a reduced set of features suitable for serving static assets that don't require a build step.`}</p>
    <p>{`All of these are available to Rails devs, but require slightly different setups. The rest of this guide describes the setup for each in detail.`}</p>
    <h2>{`How to use this guide`}</h2>
    <p>{`This guide is designed to get your Rails app up and running with primer_view_components and offers sub-sections for each of the technologies referenced above. Generally speaking, follow these steps:`}</p>
    <ol>
      <li parentName="ol">{`Follow the list of `}<a parentName="li" {...{
          "href": "#common-steps"
        }}>{`common steps`}</a>{` below.`}</li>
      <li parentName="ol">{`Find the sub-section for your app's front-end setup.`}
        <ol parentName="li">
          <li parentName="ol">{`For all-in-one solutions, follow the sub-section for `}<a parentName="li" {...{
              "href": "#sprockets"
            }}>{`Sprockets`}</a>{` or `}<a parentName="li" {...{
              "href": "#propshaft"
            }}>{`Propshaft`}</a>{`.`}</li>
          <li parentName="ol">{`Otherwise, follow the sub-sections for your `}<a parentName="li" {...{
              "href": "#javascript-bundlers"
            }}>{`JavaScript bundler`}</a>{` and `}<a parentName="li" {...{
              "href": "#css-bundlers"
            }}>{`CSS bundler`}</a>{` respectively.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`Common steps`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install the primer_view_components gem. You can add it to your Gemfile and run `}<inlineCode parentName="p">{`bundle install`}</inlineCode>{` or run `}<inlineCode parentName="p">{`bundle add primer_view_components`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install the @primer/view-components, @primer/css, and @primer/primitives JavaScript packages from NPM using yarn (eg. `}<inlineCode parentName="p">{`yarn add @primer/view-components`}</inlineCode>{`, etc). If your application doesn't have a package.json, install yarn via `}<inlineCode parentName="p">{`npm install -g yarn`}</inlineCode>{`, then run `}<inlineCode parentName="p">{`yarn init`}</inlineCode>{`. If you're using Bun, run `}<inlineCode parentName="p">{`bun init`}</inlineCode>{` instead.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following lines to the very bottom of config/application.rb:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-ruby"
          }}>{`require "view_component"
require "primer/view_components"
`}</code></pre>
      </li>
    </ol>
    <h2>{`All-in-one solutions`}</h2>
    <h3>{`Sprockets`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add your node_modules/ directory to Sprocket's load path by adding the following line to config/application.rb:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-ruby"
          }}>{`config.assets.paths << Rails.root.join("node_modules")
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If it's not already there, add the following line to the Sprockets manifest at app/assets/config/manifest.js. This ensures Sprockets will precompile all the .js files in the app/javascript/ directory:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`//= link_directory ../javascript .js
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Inside app/javascript/application.js (create it if it doesn't exist), require primer_view_component's pre-bundled JavaScript file. Sprockets knows about this file because we added node_modules/ to its load path earlier:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`//= require @primer/view-components/app/assets/javascripts/primer_view_components.js
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following lines to app/assets/stylesheets/application.css. These lines import shared CSS styles from @primer/css, primer_view_component's CSS styles, and a bunch of CSS variables from @primer/primitives. Notice we're also importing several color themes named "light" and "dark." The `}<a parentName="p" {...{
            "href": "/foundations/primitives/getting-started#usage"
          }}>{`full list of supported themes`}</a>{` is available in the Primer CSS documentation.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-css"
          }}>{`//= require @primer/css/dist/primer.css
//= require @primer/view-components/app/assets/styles/primer_view_components.css
//= require @primer/primitives/dist/css/primitives.css
//= require @primer/primitives/dist/css/functional/themes/light.css
//= require @primer/primitives/dist/css/functional/themes/dark.css
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following inside the `}<inlineCode parentName="p">{`<head></head>`}</inlineCode>{` tags in app/views/layouts/application.html.erb:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-erb"
          }}>{`<%= javascript_include_tag "application", "data-turbo-track": "reload" %>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Also in app/views/layouts/application.html.erb, add the following attributes to the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` tag. If you chose to bundle different themes, use the names of those themes instead:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-html"
          }}>{`<body data-color-mode="light" data-light-theme="light" data-dark-theme="dark">
`}</code></pre>
      </li>
    </ol>
    <h3>{`Propshaft`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add your node_modules/ directory to Propshaft's load path by adding the following line to config/application.rb:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-ruby"
          }}>{`config.assets.paths << Rails.root.join("node_modules")
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following inside the `}<inlineCode parentName="p">{`<head></head>`}</inlineCode>{` tags in app/views/layouts/application.html.erb. These lines request primer_view_component's JavaScript code, shared CSS styles from @primer/css, primer_view_component's CSS styles, and a bunch of CSS variables from @primer/primitives. Notice we're also pulling in several color themes named "light" and "dark." The `}<a parentName="p" {...{
            "href": "/foundations/primitives/getting-started#usage"
          }}>{`full list of supported themes`}</a>{` is available in the Primer CSS documentation.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-erb"
          }}>{`<%= stylesheet_link_tag "@primer/css/dist/primer.css", "data-turbo-track": "reload" %>
<%= stylesheet_link_tag "@primer/view-components/app/assets/styles/primer_view_components.css", "data-turbo-track": "reload" %>
<%= stylesheet_link_tag "@primer/primitives/dist/css/primitives.css", "data-turbo-track": "reload" %>
<%= stylesheet_link_tag "@primer/primitives/dist/css/functional/themes/light.css", "data-turbo-track": "reload" %>
<%= stylesheet_link_tag "@primer/primitives/dist/css/functional/themes/dark.css", "data-turbo-track": "reload" %>

<%= javascript_include_tag "@primer/view-components/app/assets/javascripts/primer_view_components.js", "data-turbo-track": "reload" %>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Also in app/views/layouts/application.html.erb, add the following attributes to the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` tag. If you chose to bundle different themes, use the names of those themes instead:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-html"
          }}>{`<body data-color-mode="light" data-light-theme="light" data-dark-theme="dark">
`}</code></pre>
      </li>
    </ol>
    <h2>{`JavaScript bundlers`}</h2>
    <p>{`Rails 7 supports a number of JavaScript bundlers that all work in a similar fashion. JavaScript packages are installed via yarn, imported into an entrypoint file, and served to the browser.`}</p>
    <h3>{`Import maps`}</h3>
    <p>{`Unfortunately at the time of this writing (June 2024), it is not possible to use primer_view_components with import maps. A number of primer_view_component's JavaScript dependencies attempt to import paths that the import map isn't expecting (eg. tab-container-element).`}</p>
    <h3>{`Webpack`}</h3>
    <p>{`While Webpack can bundle both CSS and JavaScript, Rails applications that use the webpacker gem are only equipped to bundle JavaScript by default.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the following line to app/javascript/application.js:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import "@primer/view-components"
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Modify webpack.config.js by setting the output -> iife option to `}<inlineCode parentName="p">{`false`}</inlineCode>{`. This will prevent Webpack from wrapping the bundled code in an Immediately Invoked Function Expression (IIFE), which prevents defining the custom elements primer_view_components needs to work properly:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`module.exports = {
    // ...
    output: {
        iife: false
    }
    // ...
}
`}</code></pre>
      </li>
    </ol>
    <h3>{`esbuild`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the following line to app/javascript/application.js:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import "@primer/view-components"
`}</code></pre>
      </li>
    </ol>
    <h3>{`Rollup`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the following line to app/javascript/application.js:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import "@primer/view-components"
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When Rollup runs, you might see a warning of the form `}<inlineCode parentName="p">{`(!) "this" has been rewritten to "undefined"`}</inlineCode>{` printed to the console. The warning can safely be ignored. To prevent it from printing to the console, add an `}<inlineCode parentName="p">{`onwarn`}</inlineCode>{` function to the config in rollup.config.js:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`export default {
    // ...
    onwarn: (warning, warn) => {
        if (warning.code === "THIS_IS_UNDEFINED") return
        warn(warning)
    }
}
`}</code></pre>
      </li>
    </ol>
    <h3>{`Vite`}</h3>
    <p>{`The steps below assume you've installed the vite_rails gem and run the installer.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the following line to app/frontend/entrypoints/application.js:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import "@primer/view-components"
`}</code></pre>
      </li>
    </ol>
    <h3>{`Bun`}</h3>
    <p>{`Bun is an up-and-coming, fast, all-in-one JavaScript toolkit.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the following line to app/frontend/entrypoints/application.js:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import "@primer/view-components"
`}</code></pre>
      </li>
    </ol>
    <h2>{`CSS bundlers`}</h2>
    <p>{`Rails 7 supports a number of CSS bundlers that all work in a similar fashion.`}</p>
    <h3>{`postcss`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the following lines to app/assets/stylesheets/application.postcss.css. These lines import shared CSS styles from @primer/css, primer_view_component's CSS styles, and a bunch of CSS variables from @primer/primitives. Notice we're also importing several color themes named "light" and "dark." The `}<a parentName="p" {...{
            "href": "/foundations/primitives/getting-started#usage"
          }}>{`full list of supported themes`}</a>{` is available in the Primer CSS documentation.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-css"
          }}>{`@import "@primer/css/dist/primer.css";
@import "@primer/view-components/app/assets/styles/primer_view_components.css";
@import "@primer/primitives/dist/css/primitives.css";
@import "@primer/primitives/dist/css/functional/themes/light.css";
@import "@primer/primitives/dist/css/functional/themes/dark.css";
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In app/views/layouts/application.html.erb, add the following attributes to the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` tag. If you chose to bundle different themes, use the names of those themes instead:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-html"
          }}>{`<body data-color-mode="light" data-light-theme="light" data-dark-theme="dark">
`}</code></pre>
      </li>
    </ol>
    <h3>{`sass / `}<inlineCode parentName="h3">{`dartsass-rails`}</inlineCode></h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the following lines to app/assets/stylesheets/application.sass.scss. These lines import shared CSS styles from @primer/css, primer_view_component's CSS styles, and a bunch of CSS variables from @primer/primitives. Notice we're also importing several color themes named "light" and "dark." The `}<a parentName="p" {...{
            "href": "/foundations/primitives/getting-started#usage"
          }}>{`full list of supported themes`}</a>{` is available in the Primer CSS documentation.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-css"
          }}>{`@use "@primer/css/dist/primer.css";
@use "@primer/view-components/app/assets/styles/primer_view_components.css";
@use "@primer/primitives/dist/css/primitives.css";
@use "@primer/primitives/dist/css/functional/themes/light.css";
@use "@primer/primitives/dist/css/functional/themes/dark.css";
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In app/views/layouts/application.html.erb, add the following attributes to the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` tag. If you chose to bundle different themes, use the names of those themes instead:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-html"
          }}>{`<body data-color-mode="light" data-light-theme="light" data-dark-theme="dark">
`}</code></pre>
      </li>
    </ol>
    <h3>{`Tailwind`}</h3>
    <p>{`Since Tailwind is implemented as a PostCSS plugin under the hood, it supports the `}<inlineCode parentName="p">{`@import`}</inlineCode>{` directive for pulling in other CSS files.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the following lines `}<em parentName="p">{`at the top`}</em>{` of app/assets/stylesheets/application.tailwind.css (it's important they come `}<em parentName="p">{`before`}</em>{` any of the lines that begin with `}<inlineCode parentName="p">{`@tailwind`}</inlineCode>{`). These lines import shared CSS styles from @primer/css, primer_view_component's CSS styles, and a bunch of CSS variables from @primer/primitives. Notice we're also importing several color themes named "light" and "dark." The `}<a parentName="p" {...{
            "href": "/foundations/primitives/getting-started#usage"
          }}>{`full list of supported themes`}</a>{` is available in the Primer CSS documentation.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-css"
          }}>{`@import "@primer/css/dist/primer.css";
@import "@primer/view-components/app/assets/styles/primer_view_components.css";
@import "@primer/primitives/dist/css/primitives.css";
@import "@primer/primitives/dist/css/functional/themes/light.css";
@import "@primer/primitives/dist/css/functional/themes/dark.css";
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In app/views/layouts/application.html.erb, add the following attributes to the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` tag. If you chose to bundle different themes, use the names of those themes instead:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-html"
          }}>{`<body data-color-mode="light" data-light-theme="light" data-dark-theme="dark">
`}</code></pre>
      </li>
    </ol>
    <h2>{`Usage`}</h2>
    <p>{`Render Primer ViewComponents in Rails templates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Beta::Counter.new(count: 25)) %>
`}</code></pre>
    <h2>{`Dependencies`}</h2>
    <p>{`In addition to the dependencies declared in the `}<inlineCode parentName="p">{`gemspec`}</inlineCode>{`, Primer ViewComponents assumes the presence of `}<a parentName="p" {...{
        "href": "https://primer.style/css"
      }}>{`Primer CSS`}</a>{`.`}</p>
    <h2>{`More information`}</h2>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://github.com/primer/view_components"
      }}>{`primer/view_components repository`}</a>{` for more information about how to use and contribute to Primer ViewComponents. For component-specific documentation, check out the Rails section of component guidelines (example: `}<a parentName="p" {...{
        "href": "/components/action-list/rails"
      }}>{`ActionList`}</a>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      